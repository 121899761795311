
















































































































import { Vue, Component } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";

import {
  MetaModule as metaStore,
  CommonStore as commonStore,
  VehicleModule as vehicleStore,
} from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";
import Admin from "@/views/admin/AdminGeneral.vue";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination,
  },
})
export default class VehicleClass extends Vue {
  vehicleDetailPath = AdminRouter.VehicleDetails;
  vehicleRouteChangePath = AdminRouter.VehicleRouteChange;
  palikas: any[] = [];
  vehiclePath = AdminRouter.VehicleRegister;
  qVehicle: any[] = [];
  vehicleList: any[] = [];
  districtName: any = [];
  ownerName: string = "";
  approvalStatus: string = "";
  filter = {
    name: "",
    district: "",
    palika: "",
  };

  get AdminRouter() {
    return AdminRouter;
  }

  get routePath() {
    return AdminRouter;
  }
  get districts() {
    return metaStore.districtL;
  }
  async created() {
    metaStore.loadDis();
    let vm = this;
    const id = vm.$route.query["vehicle_owner"];
    commonStore.setVehicleOwnerId(id);
    await vehicleStore.loadAllVehicleByOwner(id);
    await vehicleStore.getOwnerName(id);
    await vehicleStore.getApprovalStatus(id);
    this.ownerName = vehicleStore.ownerName;
    this.approvalStatus = vehicleStore.approvalStatus;
    this.vehicleList = vehicleStore.vehicleList;
  }

  edit(id: any) {
    this.$router.push(AdminRouter.VehicleRegister + "?id=" + id);
  }
  async filterPalika() {
    this.filter.palika = "";
    this.palikas = await metaStore.loadMuncipality(
      parseInt(this.filter.district)
    );
  }
  filterVehicle() {
    var filterData = vehicleStore.vehicleList;
    console.log(filterData);

    if (this.filter.name !== "") {
      this.filter.name = this.filter.name.toLowerCase();
      filterData = filterData.filter((x) =>
        x.vehicleNumber.toLowerCase().includes(this.filter.name)
      );
    }

    this.vehicleList = filterData;
  }
  clearFilter() {
    this.filter = {
      name: "",
      district: "",
      palika: "",
    };
    this.vehicleList = vehicleStore.vehicleList;
  }
  submit(e: any) {
    vehicleStore.createVehicle();
  }
}
